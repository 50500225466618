import { LocalSources, Source } from '@outmind/types';
import React, { useEffect, useMemo, useState } from 'react';
import useRouter from 'use-react-router';

import { SeparatedPaths } from '../../..';
import {
  useConnectorRoots,
  useCreateLocalDiskConnector,
  useStartLocalSync,
  useTranslations,
  useUpdateConnector,
} from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { Button, CircularProgress, DialogContentText, TextField } from '../../../material';
import { CustomDialog } from '../../CustomDialog';
import { SelectLocalRoots } from '../../RootsSelection/SelectLocalRoots';
import { SourceLogo } from '../../SourceLogo';
import { useStyles } from '../styles';
import { CancelConfigureLocalConnector } from './CancelConfigureLocalDisk';

export const ConfigureLocalConnectorDialog: React.FC<ConfigureLocalConnectorDialogProps> = ({
  connectorId,
  editDisabled,
  isManagingSource,
  name,
  source,
  setShouldShowDialog,
  shouldShowDialog,
}) => {
  const classes = useStyles({ shouldDisplayRootsFullHeight: true });

  const { t } = useTranslations();

  const { history } = useRouter();

  const [dialogIsOpen, setDialogIsOpen] = useState(true);
  const [connectorName, setConnectorName] = useState(name ?? t(source as TranslationKey));
  const [machineId, setMachineId] = useState<string>();
  const [startSyncButtonIsLoading, setStartSyncButtonIsLoading] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);

  const { data: roots = [] } = useConnectorRoots(connectorId!, source, !!connectorId);

  const [separatedPaths, setSeparatedPaths] = useState<SeparatedPaths>({
    fileServerPaths: [],
    localDiskPaths: [],
  });

  const [showCancelConfigureSourceDialog, setShowCancelConfigureSourceDialog] = useState(false);
  const removeFolder = (folderId: string): void =>
    setSelectedFolders(selectedFolders.filter((item) => item !== folderId));
  const makeParents = (filePath: string): string[] => {
    const tmpParents = filePath.split(/[\\/]/);
    if (tmpParents[0] === '') {
      tmpParents.shift();
    }
    tmpParents.pop();
    if (tmpParents.length === 0) return ['/'];

    return tmpParents.reduce(
      (prev, curr, index) => {
        prev.push(`${prev[index]}${index === 0 ? '' : '/'}${curr}`);
        return prev;
      },
      ['/'],
    );
  };

  useEffect(() => {
    setShouldShowDialog?.(dialogIsOpen);
  }, [dialogIsOpen]);

  useEffect(() => {
    if (roots.length) {
      const syncedRootsPaths = roots.map((root) => root.id);
      setSeparatedPaths({
        fileServerPaths: source === Source.NetworkDisk ? syncedRootsPaths : [],
        localDiskPaths: source === Source.LocalDisk ? syncedRootsPaths : [],
      });
      setSelectedFolders(syncedRootsPaths);
    }
  }, [roots]);

  const handleRegex = (
    str: string,
    folder: string,
    regexp: RegExp,
    replaceStr?: string,
  ): [string, string[]] | undefined => {
    const matches = regexp.exec(str);

    if (!matches) return undefined;

    return [str.replace(regexp, replaceStr ?? folder), Array.from(matches)];
  };

  const handleChildren = (str: string, folder: string): [string, string[]] | undefined =>
    handleRegex(str, folder, new RegExp(`(?:${folder})(?:/.*)`, 'gm'));

  const handleParents = (str: string, folder: string): [string, string[]] | undefined => {
    const parents = makeParents(folder);
    return parents
      .map((parent) => {
        const regexp = new RegExp(`(?:${parent})$`, 'gm');
        return handleRegex(str, parent, regexp, folder);
      })
      .find((x) => x !== undefined);
  };

  const filterFolders = (
    stateFolders: string[],
    folders: string[],
  ): [string[], Record<string, string[]>] => {
    const stateFoldersString = stateFolders.join('\n');
    const [foldersToAdd, foldersToWarn] = folders.reduce(
      ([str, toWarn], folder) => {
        const [tmpStr, tempWarn] = handleChildren(str, folder) ||
          handleParents(str, folder) || [undefined, undefined];
        if (tmpStr && tempWarn) return [tmpStr, { ...toWarn, [folder]: tempWarn }];
        return [str.concat(str !== '' ? '\n' : '', folder), toWarn];
      },
      [stateFoldersString, {}] as [string, Record<string, string[]>],
    );
    return [Array.from(new Set(foldersToAdd.split('\n'))), foldersToWarn];
  };

  const selectFolders = async (): Promise<void> => {
    const folders = await window.electron?.getLocalDiskFolders();

    if (folders && !folders.canceled && folders.separatedPaths) {
      setSelectedFolders(filterFolders(selectedFolders, folders.filePaths)[0]);
      setSeparatedPaths({
        fileServerPaths: [
          ...separatedPaths.fileServerPaths,
          ...folders.separatedPaths.fileServerPaths,
        ],
        localDiskPaths: [
          ...separatedPaths.localDiskPaths,
          ...folders.separatedPaths.localDiskPaths,
        ],
      });
    }
  };

  const { mutate: createConnector } = useCreateLocalDiskConnector();
  const { mutate: startLocalSync } = useStartLocalSync();
  const { mutateAsync: updateConnector } = useUpdateConnector();

  const updateLocalConnector = async (): Promise<void> => {
    if (connectorId) {
      await updateConnector(
        {
          connectorId,
          name: connectorName,
          source,
        },
        {
          onSuccess: () => {
            setDialogIsOpen(false);
          },
        },
      );
    }
  };

  const onClick = async (): Promise<void> => {
    setStartSyncButtonIsLoading(true);
    if (isManagingSource) await updateLocalConnector();
    else {
      await createConnector(
        {
          folders: selectedFolders,
          machineId: machineId!,
          name: connectorName,
          source,
        },
        {
          onSuccess: ({ connectorId: _connectorId }) => {
            startLocalSync({ connectorId: _connectorId, roots: selectedFolders });
            setDialogIsOpen(false);
            history.push('/');
          },
        },
      );
    }
  };

  useEffect((): void => {
    if (window.electron) {
      // Retrieves the `machineId` from the main process
      window.electron.getMachineId().then((_id) => setMachineId(_id));

      // Allow to by-pass the files explorater in electron app
      const urlParams = new URLSearchParams(window.location.search);
      const pwDirs = urlParams.get('pw_dirs');
      const pwServDirs = urlParams.get('pw_net_dirs');

      const folders = pwDirs?.split('!pw!') ?? [];
      const servFolders = pwServDirs?.split('!pw!') ?? [];
      setSelectedFolders([...folders, ...servFolders]);
      setSeparatedPaths({
        fileServerPaths: [...servFolders],
        localDiskPaths: [...folders],
      });
    }
  }, []);

  const { isNetworkDiskOutOfScope, isLocalDiskOutOfScope } = useMemo(
    () =>
      selectedFolders.reduce(
        (outOfScope, folder) => ({
          isLocalDiskOutOfScope:
            outOfScope.isLocalDiskOutOfScope ||
            (source === Source.LocalDisk && !separatedPaths.localDiskPaths.includes(folder)),
          isNetworkDiskOutOfScope:
            outOfScope.isNetworkDiskOutOfScope ||
            (source === Source.NetworkDisk && !separatedPaths.fileServerPaths.includes(folder)),
        }),
        { isLocalDiskOutOfScope: false, isNetworkDiskOutOfScope: false } as {
          isLocalDiskOutOfScope: boolean;
          isNetworkDiskOutOfScope: boolean;
        },
      ),
    [separatedPaths],
  );

  const isStartSynchronisationDisabled =
    !connectorName || !selectedFolders.length || startSyncButtonIsLoading || editDisabled;

  const onClose = (): void => {
    if (isManagingSource) {
      setDialogIsOpen(false);
    } else {
      setShowCancelConfigureSourceDialog(true);
    }
  };

  return (
    <CustomDialog
      classes={{ paper: classes.singleDialog }}
      classNames={{ content: classes.dialogContent }}
      onClose={onClose}
      open={shouldShowDialog ?? dialogIsOpen}
      title={isManagingSource ? t('source_settings') : t('configure_new_source')}
    >
      <DialogContentText>{t('source_name')}</DialogContentText>
      <div className={classes.connectorHeader}>
        <div className={classes.connectorLogoContainer}>
          <SourceLogo className={classes.connectorLogo} source={source} unknownSourceTooltip />
        </div>
        <TextField
          className={classes.connectorNameInput}
          disabled={editDisabled}
          onChange={(e) => setConnectorName(e.target.value)}
          placeholder={t('connector_name')}
          value={connectorName}
          variant="outlined"
        />
      </div>
      <DialogContentText className={classes.dialogSubtitle}>
        {isManagingSource ? t('synced_items') : t('choose_synced_items')}
      </DialogContentText>
      <div className={classes.selectRootsContainer}>
        <SelectLocalRoots
          disabled={isManagingSource}
          folders={selectedFolders}
          noRootToSync={selectedFolders.length === 0}
          removeFolder={removeFolder}
          selectFolders={selectFolders}
          separatedPaths={separatedPaths}
          source={source}
        />
      </div>
      <div className={classes.startSyncButtonContainer}>
        {isNetworkDiskOutOfScope || isLocalDiskOutOfScope ? (
          <div className={classes.errorMessage}>
            {isLocalDiskOutOfScope
              ? t('please_remove_out_of_scope_folders_networkdisk')
              : t('please_remove_out_of_scope_folders_localdisk')}
          </div>
        ) : null}

        <Button
          className={classes.startSyncButton}
          color="primary"
          disabled={isStartSynchronisationDisabled}
          onClick={onClick}
          size="large"
          variant="outlined"
        >
          {isManagingSource ? t('save') : t('start_synchronization')}
          {startSyncButtonIsLoading ? (
            <CircularProgress className={classes.startSyncButtonLoader} size={20} />
          ) : null}
        </Button>
      </div>
      {!isManagingSource ? (
        <CancelConfigureLocalConnector
          onClose={() => setShowCancelConfigureSourceDialog(false)}
          onDelete={() => history.push('/')}
          open={showCancelConfigureSourceDialog}
        />
      ) : null}
    </CustomDialog>
  );
};

interface ConfigureLocalConnectorDialogProps {
  connectorId?: string;
  editDisabled?: boolean;
  isManagingSource?: boolean;
  name?: string;
  setShouldShowDialog?: (bool: boolean) => void;
  shouldShowDialog?: boolean;
  source: LocalSources;
}
