import { ExtendedSources } from '@outmind/types';
import React, { useState } from 'react';

import { useTranslations } from '../../hooks';
import { useAvailableSources } from '../../hooks/useAvailableSources';
import { DialogContentText, Grid, LockIcon, Typography } from '../../material';
import { AddSourceCustomDialog } from '../CustomDialog/AddSourceCustomDialog';
import { AddSourceButton } from './AddSourceButton';
import { AddGSuiteButton } from './AddSourceButton/AddGSuiteButton';
import { AddOffice365Button } from './AddSourceButton/AddOffice365Button';
import { AddSourceCredentialsDialog } from './AddSourceButton/AddSourceCredentialsDialog';
import { AddSourceConfirmationDialog } from './AddSourceConfirmationDialog';
import { PrivacyReminder } from './PrivacyReminder';
import { useStyles } from './styles';

export const AddSourceDialog: React.FC<AddSourceDialogProps> = ({
  onboardingMode,
  open,
  close,
}) => {
  const [showCredentialsDialog, setShowCredentialsDialog] = useState(false);
  const [showAddSourceConfirmationDialog, setShowAddSourceConfirmationDialog] = useState(false);
  const [chosenSource, setChosenSource] = useState<ExtendedSources>();

  const { t } = useTranslations();

  const classes = useStyles();

  const dialogTitle = onboardingMode
    ? t('add_source_dialog_title_onboarding')
    : t('add_source_dialog_title');

  const dialogSubTitle = onboardingMode ? t('add_source_dialog_sub_title') : null;
  const dialogContentLoginPasswordText = t('add_source_login_password_helper');
  const securityHelperText = t('add_source_security_helper');

  const { betaSources, disabledSources, sources } = useAvailableSources();
  const addSourceButtons = sources.map((source) => (
    <AddSourceButton
      key={source}
      beta={betaSources.includes(source)}
      closeAddSourceModal={close}
      disabled={disabledSources.includes(source)}
      setChosenSource={setChosenSource}
      setShowAddSourceConfirmationDialog={setShowAddSourceConfirmationDialog}
      setShowCredentialsDialog={setShowCredentialsDialog}
      source={source}
    />
  ));

  const dialogContent =
    showCredentialsDialog && chosenSource ? (
      <div className={classes.addSourceDialogContainer}>
        <DialogContentText className={classes.addSourceSecurityHelper}>
          <LockIcon />
          <Typography className={classes.addSourceSecurityHelperText} variant="caption">
            {securityHelperText}
          </Typography>
        </DialogContentText>
        <DialogContentText>{dialogContentLoginPasswordText}</DialogContentText>
        <AddSourceCredentialsDialog source={chosenSource} />
      </div>
    ) : (
      <Grid container>
        <Grid item md={7}>
          <div className={classes.elementsContainer}>
            <DialogContentText component="div">
              <Typography className={classes.dialogTitle} variant="h6">
                {dialogTitle}
              </Typography>
              <Typography className={classes.dialogSubTitle} variant="subtitle1">
                {dialogSubTitle}
              </Typography>
            </DialogContentText>
            <Grid className={classes.addSourceButtonsContainer} container spacing={2}>
              <AddOffice365Button
                disabled={disabledSources.includes('o365')}
                setChosenSource={setChosenSource}
                setShowAddSourceConfirmationDialog={setShowAddSourceConfirmationDialog}
              />
              <AddGSuiteButton
                disabled={disabledSources.includes('gsuite')}
                setChosenSource={setChosenSource}
                setShowAddSourceConfirmationDialog={setShowAddSourceConfirmationDialog}
              />
              {addSourceButtons}
            </Grid>
          </div>
        </Grid>
        <Grid className={classes.grayArea} item md={5}>
          <div className={classes.elementsContainer}>
            <PrivacyReminder />
          </div>
        </Grid>
      </Grid>
    );

  return (
    <>
      <AddSourceCustomDialog
        onClose={() => {
          close();
          setShowCredentialsDialog(false);
        }}
        open={open}
      >
        {dialogContent}
      </AddSourceCustomDialog>
      {chosenSource ? (
        <AddSourceConfirmationDialog
          onClose={() => setShowAddSourceConfirmationDialog(false)}
          open={showAddSourceConfirmationDialog}
          source={chosenSource}
        />
      ) : null}
    </>
  );
};

interface AddSourceDialogProps {
  close: () => void;
  onboardingMode?: boolean;
  open: boolean;
}
