import React, { useEffect, useState } from 'react';

import {
  useRuntimeEnv,
  useSetSpotlightShortcut,
  useSpotlightShortcut,
  useTranslations,
} from '../../hooks';
import { Button, DialogContentText, FormControl, MenuItem, Select } from '../../material';
import { CustomDialog } from '..';
import { firstKeyBindings, secondKeyBindings } from './shortcuts';
import { useStyles } from './styles';

const DEFAULT_FIRST_KEY = 'Control';
const DEFAULT_SECOND_KEY = 'Space';

export const SpotlightShortcutConfigDialog: React.FC<SpotlightShortcutConfigDialogProps> = ({
  open,
  close,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const { inMacOS } = useRuntimeEnv();

  const { data: shortcut } = useSpotlightShortcut();
  const { mutateAsync: setShortcut } = useSetSpotlightShortcut();

  useEffect(() => {
    if (shortcut) {
      setFirstKey(shortcut.firstKey);
      setSecondKey(shortcut.secondKey);
    }
  }, [shortcut]);

  const [firstKey, setFirstKey] = useState(DEFAULT_FIRST_KEY);
  const [secondKey, setSecondKey] = useState(DEFAULT_SECOND_KEY);

  const resetShortcut = (): void => {
    setFirstKey(() => DEFAULT_FIRST_KEY);
    setSecondKey(() => DEFAULT_SECOND_KEY);
    setSpotlightShortcut();
  };

  const setSpotlightShortcut = (): void => {
    setShortcut(`${firstKey}+${secondKey}`);
    close();
  };

  return (
    <CustomDialog onClose={() => close()} open={open} title={t('keyboard_shortcut')}>
      <div className={classes.dialogInnerContainer}>
        <DialogContentText>{t('keyboard_shortcut_helper')}</DialogContentText>
        <div className={classes.selectContainer}>
          <FormControl variant="outlined">
            <div className={classes.selectCommandContainer}>
              <Select
                className={classes.firstKeySelect}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setFirstKey(() => event.target.value as string);
                }}
                value={firstKey}
              >
                {firstKeyBindings.map((command) =>
                  command.OS === 'all' || inMacOS ? (
                    <MenuItem key={command.cmd} value={command.cmd}>
                      {command.name}
                    </MenuItem>
                  ) : null,
                )}
              </Select>
            </div>
          </FormControl>
          <div className={classes.addSeperatorContainer}>
            <span>+</span>
          </div>
          <FormControl className={classes.selectContainer} variant="outlined">
            <div className={classes.selectCommandContainer}>
              <Select
                className={classes.secondKeySelect}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setSecondKey(() => event.target.value as string);
                }}
                value={secondKey}
              >
                {secondKeyBindings.map((command) => (
                  <MenuItem key={command} value={command}>
                    {command}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </div>
        <div className={classes.footer}>
          <Button onClick={resetShortcut}>{t('reset')}</Button>
          <Button color="primary" onClick={setSpotlightShortcut} variant="contained">
            {t('confirm')}
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

interface SpotlightShortcutConfigDialogProps {
  close: () => void;
  open: boolean;
}
