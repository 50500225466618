import React, { useState } from 'react';

import { useTranslations } from '../../../hooks';
import { Tooltip } from '../../../material';
import { AddButton } from '../../MaterialComponents/AddButton';
import { AddSourceDialog } from '../AddSourceDialog';

export const AddSourceDialogButtonIcon: React.FC = () => {
  const { t } = useTranslations();

  const [open, setOpen] = useState(false);

  return (
    <div id="add-source-button">
      <Tooltip aria-label="Add" arrow placement="right" title={t('add_source')}>
        <div>
          <AddButton onClick={() => setOpen(true)} />
        </div>
      </Tooltip>
      <AddSourceDialog close={() => setOpen(false)} open={open} />
    </div>
  );
};
